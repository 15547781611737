import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HomeLayout from './HomeLayout';

const Home = () => {
    const [userData, setUserData] = useState({ username: '', streak: 0, currentTeamPick: '', correct: 0, incorrect: 0, longeststreak: 0 });
    const [teamPick, setTeamPick] = useState('');
    const [topUsers, setTopUsers] = useState({}); // New state for top users
    const [todaysGame, setTodaysGame] = useState({}); 
    const [loading, setLoading] = useState(true);
    const [showCongratsPopup, setShowCongratsPopup] = useState(false);

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            navigate('/');
            return;
        }

        const fetchData = async () => {
            try {
                const userDataResponse = await axios.get('https://betlserver-9d063464adb0.herokuapp.com/getUserData', { headers: { Authorization: `Bearer ${authToken}` } });
                const topUsersResponse = await axios.get('https://betlserver-9d063464adb0.herokuapp.com/getTopUsers');
                const todaysGameResponse = await axios.get('https://betlserver-9d063464adb0.herokuapp.com/getTodaysGame');

                if (userDataResponse.data.success) {
                    setUserData({
                        username: userDataResponse.data.userData.username,
                        winningStreak: userDataResponse.data.userData.winning_streak,
                        correct: userDataResponse.data.userData.right_count,
                        incorrect: userDataResponse.data.userData.wrong_count,
                        longeststreak: userDataResponse.data.userData.longest_streak,
                        currentTeamPick: userDataResponse.data.userData.current_team_pick || ''
                    });
                    setTeamPick(userDataResponse.data.userData.current_team_pick || '');
                }
                if (topUsersResponse.data.success) {
                    setTopUsers(topUsersResponse.data.topUsers);
                }
                if (todaysGameResponse.data.success) {
                    console.log(todaysGameResponse.data.gameForToday)
                    setTodaysGame(todaysGameResponse.data.gameForToday);
                }
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    setErrorMessage('Too many requests, try again in 15 minutes.');
                } else {
                    console.error('Error fetching data:', error);
                }
                navigate('/loading');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    const handleTeamSelect = (team) => {
        setTeamPick(team);
    };

    const handleTeamSubmit = () => {
        const authToken = localStorage.getItem('authToken');
        axios.post('https://betlserver-9d063464adb0.herokuapp.com/updateTeamPick', { teamPick }, { 
            headers: { Authorization: `Bearer ${authToken}` } 
        })
        .then(response => {
            if (response.data.success) {
                setUserData({ ...userData, currentTeamPick: teamPick });
                console.log('Team updated successfully');
                setShowCongratsPopup(true);
                
            }
        })
        .catch(error => {
            console.error('Error updating team pick:', error);
        });
    };

    const handleSignOut = () => {
        localStorage.removeItem('authToken');
        navigate('/');
    };
    //check to see if it is time where button should be disabled
    const isSubmitDisabled = () => {
        const now = new Date();
        const pstTime = new Date(now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
        const hours = pstTime.getHours();
        const minutes = pstTime.getMinutes();
        
        // Disable submit button between 4 PM PST (16) and 1:05 AM PST
        if (hours >= 16) {
            // After 4 PM, disable
            return true;
        } else if (hours < 1) {
            // Before 1 AM, disable
            return true;
        } else if (hours === 1 && minutes <= 5) {
            // Between 1:00 AM and 1:05 AM, disable
            return true;
        }
        // Else, enable
        return false;
    };

    const todayDate = new Date().toLocaleDateString();
    if (loading) {
        return <div>Loading...</div>; // Display a simple loading message or custom loading component
    }
    if (errorMessage) {
        return <div>{errorMessage}</div>; // Display the error message if there is one
    }
    //const currentTime = new Date(now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
    return (
        <HomeLayout 
            userData={userData}
            handleTeamSelect={handleTeamSelect}
            handleTeamSubmit={handleTeamSubmit}
            isSubmitDisabled={isSubmitDisabled()}
            handleSignOut={handleSignOut}
            teamPick={teamPick}
            topUsers={topUsers}
            todaysGame={todaysGame}
            todayDate={todayDate}
            showCongratsPopup={showCongratsPopup} // Pass the state to control popup visibility
            setShowCongratsPopup={setShowCongratsPopup}
        />
        
    );
};

export default Home;