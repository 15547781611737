import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Validation from './SignUpValidation';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import './signup.css';

function Signup() {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: ''
    });
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const handleInput = event => {
        const { name, value } = event.target;
        const processedValue = 
            (name === 'email' || name === 'name') 
            ? value.trim().toLowerCase() 
            : value.trim();

        setValues(prev => ({ ...prev, [name]: processedValue }));
    };

    const onCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const err = Validation(values);
        setErrors(err);
        
        if (Object.values(err).every(e => e === "") && captchaValue) {
            axios.post('https://betlserver-9d063464adb0.herokuapp.com/signup', values)
            .then(res => {
                navigate('/login');
            })
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    // Handle email already in use error
                    setErrors(prevErrors => ({ ...prevErrors, email: error.response.data.error }));
                } else {
                    console.log(error);
                }
            });
        } else {
            // Handle the case where the captcha is not solved.
            setErrors(prevErrors => ({ ...prevErrors, captcha: "Please solve the captcha." }));
        }
    };

    return(
        <div className='d-flex justify-content-center align-items-center bg-black vh-100'>
        <div className='bg-light p-3 rounded'>
            <h2>Sign-Up</h2>
            <form action="" onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <label htmlFor="name">Name</label>
                    <input type="text" onChange={handleInput} placeholder='Enter Name' name='name' className='form-control rounded-0'/>
                    {errors.name && <span className='text-danger'> {errors.name} </span>}
                </div>
                <div className='mb-3'>
                    <label htmlFor="email">Email</label>
                    <input type="email" onChange={handleInput} placeholder='Enter Email' name='email' className='form-control rounded-0'/>
                    {errors.email && <span className='text-danger'> {errors.email} </span>}
                </div>
                <div className='mb-3'>
                    <label htmlFor="password">Password</label>
                    <input type="password" onChange={handleInput} placeholder='Enter Password' name='password' className='form-control rounded-0'/>
                    {errors.password && <span className='text-danger'> {errors.password} </span>}
                </div>
                <ReCAPTCHA
                        sitekey="6LdW-6YpAAAAAF_ut8pLUzu3SdU1WQOXpiXM0vIM"
                        onChange={onCaptchaChange}
                    />
                <button type='submit' className='btn btn-success w-100'><strong>Sign Up</strong></button>
                <p>You agree to our terms and policies</p>
                <Link to='/login' className='btn btn-default border w-100'>Log In</Link>
            </form>
        </div>
        
    </div>
    )
}
export default Signup