import React, {useState, useEffect} from 'react' 

import './landingpagecss.css'
import HeroSection from './HeroSection';
import SportsSection from './SportsSection';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; // Import Footer component

function Landingpage({ setIsLoggedIn }){
    const navigate = useNavigate();
    const navigateToUp = () => {
        navigate('/signup');
      };
      const navigateToIn = () => {
        navigate('/login');
      };
      useEffect(() => {
        // Check if user is already logged in
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLoggedIn(true);
            navigate('/home');
        }
    }, [setIsLoggedIn, navigate]);
    return (
      
        <div className="home-layout2">
          
        <nav className="navbar">
            <div className="logo"><img src="betl.jpg" /></div>
            <div className="nav-icons">
                <button className="button-7" onClick={navigateToUp} role="button">Sign Up</button>
                <button className="button-6" onClick={navigateToIn} role="button">Sign In</button>
            </div>
        </nav>

        <div className="container">
        <HeroSection />
        {/*<SportsSection />*/}
        
        </div>
        <Footer /> 
        </div>
      );

}
export default Landingpage;