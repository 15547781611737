import React from 'react';
import './HeroSection.css'; // Make sure to create this CSS file

const HeroSection = () => {
  return (
    <section className="hero">
      <div className="herocontainer">
        <div>
      <img src="p2.png" alt="Head" className="headimage" />
      
      <button onClick={() => window.location.href = 'login'} class="play-now desktop">Play Now</button>
      <img src="p.png" alt="Head" className="headimage" />
      
      </div>
      <button onClick={() => window.location.href = 'login'} class="play-now mobile">Play Now</button>
      <img src="landingimg.png" alt="Game Screenshot" className="screenshot" />
      </div>
      
      
      
      
    </section>
    
  );
}

export default HeroSection;