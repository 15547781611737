import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('https://betlserver-9d063464adb0.herokuapp.com/forgotPassword', { email })
      .then(response => {
        alert("If there is an account associated with this email, you will receive a password reset link.");
        navigate('/login'); // Navigate back to login page or another page of your choice
      })
      .catch(error => {
        console.error("Error sending forgot password request:", error);
        alert("Error sending forgot password request. Please try again later.");
      });
  };

  return (
    <div className='d-flex justify-content-center align-items-center bg-black vh-100'>
      <div className='bg-dark p-3 rounded'>
        <h2 className='text-white'>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label className='text-white' htmlFor="email">Email</label>
            <input type="email" placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)} className='form-control rounded-0' name='email' required />
          </div>
          <button type='submit' className='btn btn-success w-100'><strong>Send Reset Link</strong></button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;