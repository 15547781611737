import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material';

const CongratsPopup = ({ isOpen, selectedTeam, onClose, children }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    if (isOpen) {
        setProgress(0);
        // Update the progress every 100ms for a smoother transition
        timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              clearInterval(timer);
              onClose(); // Automatically close popup
              return 100;
            }
            const diff = 100 / (6 * 10); // Increment to reach 100% in 8 seconds, updating every 100ms
            return Math.min(oldProgress + diff, 100);
          });
        }, 100); // Update progress every 100 milliseconds
      }

    return () => {
      clearInterval(timer);
    };
  }, [isOpen, onClose]);

  const handleShare = () => {
    navigator.clipboard.writeText(React.Children.map(children, child => child.props.children).join('')).then(() => {
        alert('Leaderboard copied to clipboard!');
        console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="congrats-dialog-title">
      
      <DialogContent dividers>
        {children}
        <Button onClick={handleShare} color="primary" variant="contained">Share</Button>
        <Button onClick={onClose} color="secondary" variant="outlined" style={{ marginLeft: '10px' }}>Close</Button>
        <LinearProgress variant="determinate" value={progress} style={{ marginTop: '10px' }} />
      </DialogContent>
    </Dialog>
  );
};

export default CongratsPopup;