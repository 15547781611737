import React, { useState } from 'react';
import './HomeLayout.css'; 
import './teamcard.css';
import './infobox.css';
import './iconbutton.css';
import Questionpopup from './questionpopup';
import CongratsPopup from './CongratsPopup';
import './graphs.css';
import CountdownTimer from './CountdownTimer';
import './CountdownTimer.css';
import './streak.css';
import ChartToggle from './charttoggle';
import Leaderboard from './leaderboard';

const HomeLayout = ({ userData, handleTeamSelect, handleTeamSubmit, handleSignOut, teamPick, todayDate, isSubmitDisabled, todaysGame, topUsers, showCongratsPopup, setShowCongratsPopup }) => {
    const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    

    const toggleLeaderboard = () => setIsLeaderboardOpen(!isLeaderboardOpen);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const currentTeamText = userData.currentTeamPick ? `${userData.currentTeamPick}` : 'No selection has been made';

    const team1logo = todaysGame['team1logo']
    const team2logo = todaysGame['team2logo']
    const team1name = todaysGame['team1']
    const team2name = todaysGame['team2']
    const gameTime = todaysGame['game_time']
    //test
    const [selectedTeam, setSelectedTeam] = useState(null);

    const selectTeam = (teamNumber) => {
        setSelectedTeam(teamNumber); // Update the selected team
        handleTeamSelect(teamNumber); // Call existing handleTeamSelect with the selected team
    };
    //test


    return (
        <div className="home-layout">
          <meta name="google-adsense-account" content="ca-pub-8097885155023630"></meta>
            <nav className="navbar">
                <div className="logo"><img src="betl.jpg" /></div>
                <div className="nav-icons">
                    <button className="button-56" role="button" onClick={toggleModal}><img src="info.png" /></button>
                    <button className="button-56" role="button" onClick={toggleLeaderboard}><img src="leaderboard.png" /></button>
                    <button className="button-56" role="button" onClick={handleSignOut}><img src="signout.svg"/></button>
                </div>
            </nav>
            <Questionpopup isOpen={isModalOpen} onClose={toggleModal} githubLink="https://github.com/rajabatra"/>
            {isLeaderboardOpen && <Leaderboard topUsers={topUsers} onClose={toggleLeaderboard}/>}
            <CongratsPopup isOpen={showCongratsPopup} selectedTeam={selectedTeam} onClose={() => setShowCongratsPopup(false)}>
              <h2>You have selected </h2>
              <p>{currentTeamText === '1' ? todaysGame['team1'] : currentTeamText === '2' ? todaysGame['team2'] : currentTeamText} to beat {currentTeamText === '2' ? todaysGame['team1'] : currentTeamText === '1' ? todaysGame['team2'] : currentTeamText}  </p>
              <p> playbetl.com</p>
            </CongratsPopup>
            <div className="container">
            <div className="box">
            <div class="text-box">
                    <div class="text-content">
                        <p class="ptext"> <span class="fancy">{userData.winningStreak}</span></p>
                        <p class="ptext5"> <span class="fancy">Day Streak</span>
                        
                        </p>
                        
                    </div>
                </div>
            </div>
            
            
            <div className="gamebox">
                <div>
                <div class="text-box2">
               <p class = "ptext2">
               <span class="cfancy">Today's Game: </span>
                
             
                
                </p>
                </div>
              
            
            <div className="grid">
            
                
                    <div className={`team ${selectedTeam === 2 ? 'team-selected' : ''}`} onClick={() => selectTeam(2)}>
                    <div className="team-content">
                        <div className="team-name">Away</div>
                    
                        <img src={todaysGame['team2logo']} alt="Team 2" />
                        
                        <div className="team-name">{team2name}</div>
                     </div>
                        
                    </div>
                    <div className={`team ${selectedTeam === 1 ? 'team-selected' : ''}`} onClick={() => selectTeam(1)}>
                        
                    <div className="team-content">
                         <div className="team-name">Home</div>
                        
                        <img src={todaysGame['team1logo']} alt="Team 1" />
                       
                        <div className="team-name">{team1name}</div>
                    </div>
                       
                </div>
                    </div>
                    {teamPick && <button onClick={handleTeamSubmit} className="submit-btn" disabled={isSubmitDisabled}>
  {isSubmitDisabled ? "Locked" : "Submit Pick"}
</button>}
                    <div className="stats-container">
                    <hr className="divider"/>
  <div className="stat-item">
    <span className="stat-label">Current Pick:</span>
    <span className="stat-value">{currentTeamText === '1' ? todaysGame['team1'] : currentTeamText === '2' ? todaysGame['team2'] : currentTeamText}</span>
  </div>
  <hr className="divider"/>
  <div className="stat-item">
    <span className="stat-label">Gametime PST:</span>
    <span className="stat-value">{gameTime}</span>
  </div>
  
</div>
                    
                   
       
            </div>
            </div>
            <div class="chartbox">
                <p class="ptext">Stats</p>
            <div className="box"><ChartToggle userdata={userData}/></div>
            <div className="stats-container">
  <div className="stat-item">
    <span className="stat-label">Username:</span>
    <span className="stat-value">{userData.username}</span>
  </div>
  <hr className="divider"/>
  <div className="stat-item">
    <span className="stat-label">Longest Streak:</span>
    <span className="stat-value">{userData.longeststreak}</span>
  </div>
  <hr className="divider"/>
  <div className="stat-item">
    <span className="stat-label">Total games played:</span>
    <span className="stat-value">{userData.correct + userData.incorrect}</span>
  </div>
  <hr className="divider"/>
  <div className="stat-item">
    <span className="stat-label">Percent Correct:</span>
    <span className="stat-value">{((userData.correct / (userData.correct + userData.incorrect)) * 100).toFixed(2)}%</span>
  </div>
</div>
            </div>
            
            
           
            
            <div className="banner-ad-placeholder">
            
        </div> 
        </div>
         
           {/* Footer */}
        <div className="footer">
        
            <CountdownTimer /> {/* This renders the countdown timer */}
           
    )
        </div>
        
        </div>
    );
};

export default HomeLayout;