import logo from './logo.svg';

import React, { useState } from 'react'
import Login from './Login'
import Signup from './Signup'
import Landing from './Landingpage'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'

import ResetPassword from './ResetPassword';
import Home from './Home'
import ForgotPassword from './ForgotPassword';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login setIsLoggedIn={setIsLoggedIn}></Login>}></Route>
        <Route path='/' element={<Landing setIsLoggedIn={setIsLoggedIn}></Landing>}></Route>
        
        <Route path='/signup' element={<Signup></Signup>}></Route>
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path='/home' element={isLoggedIn ? <Home></Home> : <Navigate to="/" />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;