// Footer.js
import React from 'react';
import './landingpagecss.css'; 

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-container">
       
        <p>© 2024 Betl</p>
        <p>PLAYBETL.COM</p>
        <a href="https://github.com/rajabatra" target="_blank" rel="noopener noreferrer" className="github-link">
          <img src="github-mark.svg" alt="GitHub" className="github-icon"/> 
        </a>
        
      </div>
    </footer>
  );
}

export default Footer;