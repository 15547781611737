import React, { useEffect, useState } from 'react';
import './questionpopup.css'; // Create and import CSS for styling

const Questionpopup = ({ isOpen, onClose, githubLink }) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    }
  }, [isOpen]);

  const handleClose = (e) => {
    e.stopPropagation();
    setShowModal(false);
    setTimeout(() => onClose(), 500); // Wait for animation to finish
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className={`modal-content ${isOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
        <h2>How To Play</h2>
        <hr className="modal-divider"/>
        <p>
          Every Day A new game will be selected at Random. Each user will have
          between 1am and 4pm PST to select their choice for the day. Picks will lock at 4pm
          and games will typically be between 4pm PST and 8pm PST. If the user correctly picked the winner,
          then their streak will go up by 1. Otherwise, it will reset to 0. Games are selected from the following sports:
          football, basketball, baseball, and soccer.
        </p>
        <hr className="modal-divider"/>
        <a href={githubLink} target="_blank" rel="noopener noreferrer">Check Out The Project</a>
        <button className="close-button" onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default Questionpopup;